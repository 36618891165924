<template>
    <div class="shop">
      <div class="header">
        <div class="topBg"></div>
        <div v-if="!loginStatus">
          <img class="logo" src="@/assets/logo.png"/>
          <div class="name">Hit Fortune - Casino Slots</div>
        </div>
        <div v-if="loginStatus">
          <div class="user_name">welcome, {{userName}}</div>
        </div>
        <div class="head_right2" v-if="!loginStatus">
          <div class="move_left">
            <div class="btn_store" @click="onClickH">
              <div class="store"></div>
              <img class="icon_home" src="@/assets/icon_home.png"/>
              <div class="text_store">HOME</div>
            </div>
            <a class="btn_download" target="_blank" href="https://galaxystore.samsung.com/detail/com.hitright.ssmasters.game">
              <div class="download"></div>
              <img class="icon_download" src="@/assets/icon_download.png"/>
              <div class="text_download">DOWNLOAD</div>
            </a>
          </div>
          <div class="login_box" @click="dialogVisible3 = true, loginFai = false">
            <img class="icon_login" src="@/assets/shop/icon_login.png" alt="">
            <div class="login">LOGIN</div>
          </div>
        </div>
        <div class="head_right" v-if="loginStatus">
            <div class="btn_store" @click="onClickH">
              <div class="order_bg"></div>
              <img class="icon_home" src="@/assets/icon_home.png"/>
              <div class="text_store">HOME</div>
            </div>
            <a class="btn_download" target="_blank" href="https://galaxystore.samsung.com/detail/com.hitright.ssmasters.game">
              <div class="logout_bg"></div>
              <img class="icon_download" src="@/assets/icon_download.png"/>
              <div class="text_download">DOWNLOAD</div>
            </a>
        </div>
        <div v-if="loginStatus">
          <img class="icon_order" src="@/assets/shop/order.png" @click="dialogVisible4 = true"/>
          <div class="order_text">ORDER</div>
          <img class="icon_logout" src="@/assets/shop/logout.png" @click="logout()"/>
          <div class="logout_text">LOGOUT</div>
        </div>
        <img class="banner_bg" src="@/assets/shop/store_banner.png"/>
        <div class="banner_class">
        <div class="banner_title">LIMITE TIME OFFER</div>
        <div class="banner_text">The event is coming to an end, please join as soon as possible!</div>
        </div>
      </div>
      <div class="main">
        <div class="main_bg"></div>  
        <div class="gold_content">
        <div class="main_title">GOLDEN COINS</div>
        <div class="shopClick" @click="onClickShopItem(0)">
            <div class="g1_bg"></div>
            <img class="g1_tag" src="@/assets/shop/tag.png"/>
            <div class="g1_pay">$0.99</div>
            <div class="g1_bg2"></div>
            <div class="g1_num">10,000 COINS</div>
            <img class="g1" src="@/assets/shop/gold1.png"/>
        </div>
        <div class="shopClick" @click="onClickShopItem(1)">
            <div class="g2_bg"></div>
            <img class="g2_tag" src="@/assets/shop/tag.png"/>
            <div class="g2_pay">$1.99</div>
            <div class="g2_bg2"></div>
            <div class="g2_num">20,000 COINS</div>
            <img class="g2" src="@/assets/shop/gold2.png"/>
        </div>
        <div class="shopClick" @click="onClickShopItem(2)">
            <div class="g3_bg"></div>
            <img class="g3_tag" src="@/assets/shop/tag.png"/>
            <div class="g3_pay">$2.99</div>
            <div class="g3_bg2"></div>
            <div class="g3_num">30,000 COINS</div>
            <img class="g3" src="@/assets/shop/gold3.png"/>
        </div>
        <div class="shopClick" @click="onClickShopItem(3)">
            <div class="g4_bg"></div>
            <img class="g4_tag" src="@/assets/shop/tag.png"/>
            <div class="g4_pay">$4.99</div>
            <div class="g4_bg2"></div>
            <div class="g4_num">50,000 COINS</div>
            <img class="g4" src="@/assets/shop/gold4.png"/>
        </div>
        <div class="shopClick" @click="onClickShopItem(4)">
            <div class="g5_bg"></div>
            <img class="g5_tag" src="@/assets/shop/tag.png"/>
            <div class="g5_pay">$9.99</div>
            <div class="g5_bg2"></div>
            <div class="g5_num">100,000 COINS</div>
            <img class="g5" src="@/assets/shop/gold5.png"/>
        </div>
        <div class="shopClick" @click="onClickShopItem(5)">
            <div class="g6_bg"></div>
            <img class="g6_tag" src="@/assets/shop/tag.png"/>
            <div class="g6_pay">$14.99</div>
            <div class="g6_bg2"></div>
            <div class="g6_num">150,000 COINS</div>
            <img class="g6" src="@/assets/shop/gold6.png"/>
        </div>
        <div class="shopClick" @click="onClickShopItem(6)">
            <div class="g7_bg"></div>
            <img class="g7_tag" src="@/assets/shop/tag.png"/>
            <div class="g7_pay">$19.99</div>
            <div class="g7_bg2"></div>
            <div class="g7_num">200,000 COINS</div>
            <img class="g7" src="@/assets/shop/gold7.png"/>
        </div>
        <div class="shopClick" @click="onClickShopItem(7)">
            <div class="g8_bg"></div>
            <img class="g8_tag" src="@/assets/shop/tag.png"/>
            <div class="g8_pay">$49.99</div>
            <div class="g8_bg2"></div>
            <div class="g8_num">500,000 COINS</div>
            <img class="g8" src="@/assets/shop/gold8.png"/>
        </div>
        <div class="shopClick" @click="onClickShopItem(8)">
            <div class="g9_bg"></div>
            <img class="g9_tag" src="@/assets/shop/tag.png"/>
            <div class="g9_pay">$99.99</div>
            <div class="g9_bg2"></div>
            <div class="g9_num">1,000,000 COINS</div>
            <img class="g9" src="@/assets/shop/gold9.png"/>
        </div>
        <div class="shopClick" @click="onClickShopItem(9)">
            <div class="g10_bg"></div>
            <img class="g10_tag" src="@/assets/shop/tag.png"/>
            <div class="g10_pay">$199.99</div>
            <div class="g10_bg2"></div>
            <div class="g10_num">2,000,000 COINS</div>
            <img class="g10" src="@/assets/shop/gold10.png"/>
        </div>
        <div class="shopClick" @click="onClickShopItem(10)">
            <div class="g11_bg"></div>
            <img class="g11_tag" src="@/assets/shop/tag.png"/>
            <div class="g11_pay">$299.99</div>
            <div class="g11_bg2"></div>
            <div class="g11_num">3,000,000 COINS</div>
            <img class="g11" src="@/assets/shop/gold11.png"/>  
        </div>
        <div class="shopClick" @click="onClickShopItem(11)">
            <div class="g12_bg"></div>
            <img class="g12_tag" src="@/assets/shop/tag.png"/>
            <div class="g12_pay">$499.99</div>
            <div class="g12_bg2"></div>
            <div class="g12_num">5,000,000 COINS</div>
            <img class="g12" src="@/assets/shop/gold12.png"/>
        </div>
      </div>
      </div>
    
      <div class="footer">
        <div class="footer_bg"></div>
        <div class="footer_content">
        <img class="footer_logo" src="@/assets/logo.png">
        <div class="footer_name">Hit Fortune - Casino Slots</div>
        <div class="footer_reserved">Copyright @ Sichuan Deqin. All rights reserved.</div>
        <div class="footer_introduce">NO PURCHASE IS NECESSARY to play. PROMOTIONS ARE VOID WHERE PROHIBITED BY LAW. For detailed rules, see <span class="termsOfUse" @click="onClickTS">Terms of Use</span>. Hit Fortune is a play-for-fun website intended for amusement purposes only. Hit Fortune does not offer "real-money gambling." Hit Fortune Platform is only open to Eligible Participants, who are at least eighteen (18) years old or the age of majority in their jurisdiction (whichever occurs later) at the time of entry.</div>
        <a class="TermsLink" @click="onClickTS">Terms of Use</a>
        <div class="PrivacyLink" @click="onClickP">Privacy Policy</div>
      </div>
    </div>
      <div class="shopPage" v-if="dialogVisible">
        <div class="dialogPage"></div>
        <img class="closeBtn" src="@/assets/shop/close.png" @click="dialogVisible = false"/>
        <div class="shopPage_box">
          <div class="item_bg">
            <img class="item_icon" :src="curItem.icon">
          </div>
          <div class="item_bg2">
            <div class="item_num">{{curItem.num}} COINS</div>
          </div>
        </div>
        <div class="golden_coins_num">{{curItem.num}} Golden Coins</div>
        <div class="payment">Payment Methord : </div>
        <img class="paypal_icon" src="@/assets/shop/PayPal.png">
          <div class="dialogPage_di"></div>
          <div class="pay_num">Amount : ${{curItem.pay}}</div>
          <a class="pay_num_btn" @click="sendOrder()">
            <div class="pay_now_bg"></div>
            <div class="pay_now">PAY NOW</div>
          </a>
      </div>
      <div class="successPage" v-if="dialogVisible2">
        <div class="dialogPage"></div>
        <img v-if="!loginPageStatues" class="closeBtn" src="@/assets/shop/close.png" @click="dialogVisible2 = false"/>
        <img class="succ_icon" src="@/assets/shop/success_icon.png">
        <div v-if="!loginPageStatues" class="succ_content">Waiting for purchase result, you can return back after successfully purchased.</div>
        <div v-if="loginPageStatues" class="succ_content2">Login successful!</div>
        <div class="succBtn" @click="dialogVisible2 = false" v-if="!loginPageStatues">
          <div class="succ_di"></div>
          <div class="succ_di_content">CONFIRM</div>
        </div>
      </div>
      <div class="loginPage" v-if="dialogVisible3">
        <div class="dialogPage"></div>
        <img class="closeBtn" src="@/assets/shop/close.png" @click="dialogVisible3 = false"/>
        <div class="login_title">LOGIN ACCOUNT</div>
        <div class="email_bg">
          <el-input v-model="inputEmail" class="user_email_input" placeholder="Please input your Email"></el-input>
        </div>
        <div class="password_bg">
          <el-input v-model="inputPassword" type="password" class="user_password_input" placeholder="Please input your Password"></el-input>
        </div>
        <div class="warn_message" v-if="loginFai">Something wrong, please check your email and password and try again.</div>
        <div class="succBtn" @click="sendLogin()">
          <div class="succ_di"></div>
          <div class="succ_di_content">LOGIN</div>
        </div>
      </div>
      <div class="orderNullPage" v-if="dialogVisible4">
        <div class="dialogPage"></div>
        <img class="closeBtn" src="@/assets/shop/close.png" @click="dialogVisible4 = false"/>
        <img class="order_null" src="@/assets/shop/icon_order_null.png" alt="">
      </div>
      <div class="orderPage" v-if="dialogVisible5">
        <div class="dialogPage2">
          <ul>
            <li v-for="(item, index) in itemData" :key="index">
              <div class="order_item_bg">
                <div>ORDER ID :  101234567890</div>
                <div>Feb 3,2024</div>
              </div>
              <div class="order">
                <div class="order_item_box_bg">
                  <img :src="item.icon">
                </div>
                <div class="order_coin_num">{{item.num}} Golden Coins</div>
                <div class="order_pay_num">${{item.pay}}</div>
              </div>
            </li>
          </ul>
        </div>
        <img class="closeBtn2" src="@/assets/shop/close.png" @click="dialogVisible5 = false"/>
      </div>
      <div class="mask" v-if="dialogVisible || dialogVisible2 || dialogVisible3 || dialogVisible4 || dialogVisible5"></div>
    </div>
  </template>
  
  <script>
//   import "@/css/shop.css"
var md5 = require('js-md5');
import { request } from '../axios/http';
  export default {
    title: "Shop",
    name: 'ShopView',
    data: function(){
      return {
        dialogVisible: false,
        dialogVisible2: false,
        dialogVisible3: false,
        dialogVisible4: false,
        dialogVisible5: false,
        loginPageStatues: false,
        loginFai: false,
        loginStatus: this.checkIsToken(),
        inputId: "",
        inputEmail: "",
        inputPassword: "",
        userName: this.checkIsToken() ? localStorage.getItem("username") : "",
        shopId: 0,
        payPalUrl: "",
        itemData: [
          {
            icon: require("@/assets/shop/gold1.png"),
            num: "10,000",
            pay: 0.99,
          },
          {
            icon: require("@/assets/shop/gold2.png"),
            num: "20,000",
            pay: 1.99,
          },
          {
            icon: require("@/assets/shop/gold3.png"),
            num: "30,000",
            pay: 2.99,
          },
          {
            icon: require("@/assets/shop/gold4.png"),
            num: "50,000",
            pay: 4.99,
          },
          {
            icon: require("@/assets/shop/gold5.png"),
            num: "100,000",
            pay: 9.99,
          },
          {
            icon: require("@/assets/shop/gold6.png"),
            num: "150,000",
            pay: 14.99,
          },
          {
            icon: require("@/assets/shop/gold7.png"),
            num: "200,000",
            pay: 19.99,
          },
          {
            icon: require("@/assets/shop/gold8.png"),
            num: "500,000",
            pay: 49.99,
          },
          {
            icon: require("@/assets/shop/gold9.png"),
            num: "1,000,000",
            pay: 99.99,
          },
          {
            icon: require("@/assets/shop/gold10.png"),
            num: "2,000,000",
            pay: 199.99,
          },
          {
            icon: require("@/assets/shop/gold11.png"),
            num: "3,000,000",
            pay: 299.99,
          },
          {
            icon: require("@/assets/shop/gold12.png"),
            num: "5,000,000",
            pay: 499.99,
          },
        ],
        curItem: {
          icon: "",
          num: "",
          pay: 0
        }
      }
    },
    props: {
    },
    mounted: function(){
      document.getElementById("body").style.backgroundColor = "#2c1f4d";
    },
    beforeDestroy: function(){
      document.getElementById("body").style.backgroundColor = "";
    },
    methods: {
      onClickTS: function(){
        this.$router.push("TermsOfUse")
      },
      onClickP: function(){
        this.$router.push("Privacy")
      },
      onClickH: function(){
        this.$router.push("/")
      },
      onClickShopItem: function(index) {
        if (this.checkIsToken()) {
          this.curItem = this.itemData[index]
          this.shopId = index + 1
          this.dialogVisible = true
        } else {
          this.loginFai = false
          this.dialogVisible3 = true
        }
      },
      logout: function() {
        this.loginStatus = false
        localStorage.removeItem("token")
        localStorage.removeItem("time")
        localStorage.removeItem("username")
        this.$message({
          message: 'logout success!',
          type: 'success'
        });
      },
      sendLogin: function() {
        request({
          url: '/v1/user/login', // 假设这是你的API端点
          method: 'post',
          data : {
            "email": this.inputEmail,
            "password": md5(this.inputPassword),
            "platform": 1,
            //"pkg_name": "web",
            "country": "HK",
            "province": "Hong Kong",
            "phone_model": "小米10",
            "phone_os_version": "安卓11",
            "app_version": "1.0.0"
          },
        })
          .then(response => {
            if (response.data.code == 1) {
              let data = JSON.parse(atob(response.data.data))
              this.dialogVisible3 = false;
              this.userName = this.usernameChange(data.user.username); // 将返回的数据存储在组件的data属性中
              this.loginPageStatues = true
              this.dialogVisible2 = true
              setTimeout(() => {
                this.dialogVisible2 = false
                this.loginPageStatues = false
              }, 2000)

              localStorage.setItem("token", data.token)
              localStorage.setItem("time", new Date().getTime())
              localStorage.setItem("username", this.usernameChange(data.user.username))

              this.loginFai = false
              this.loginStatus = true
            } else {
              this.loginFai = true
              this.loginStatus = false
            }
          })
          .catch(error => {
            console.error(error);
          });
      },
      sendOrder: function() {
        if (!this.checkIsToken()) {
          return
        }
        this.dialogVisible = true;
        this.dialogVisible2 = false
        request({
          url: '/v1/shop/order', // 假设这是你的API端点
          method: 'post',
          data : {
            "goods_id": this.shopId,
            "payment": "paypal",
            "payment_channel": "",
            "user_coupon_id": 0,
            "return_url": "https://res.buddyfunny.com/web/paypal/return/ios.html",
            "cancel_url": "https://res.buddyfunny.com/web/paypal/return/ios.html"
          },
          headers: {
            'token': localStorage.getItem("token")
          }
        })
          .then(response => {
            if (response.data.code == 1) {
              let data = JSON.parse(atob(response.data.data))
              this.payPalUrl = data.pay_url // 将返回的数据存储在组件的data属性中
              window.open(this.payPalUrl, '_blank');
              this.dialogVisible = false;
              this.dialogVisible2 = true
            } else {
              this.$message({
                message: 'The system is currently busy. Please try again in a moment',
                type: 'error'
              });
            }
          })
          .catch(error => {
            console.error(error);
          });
      },
      checkIsToken: function() {
        this.loginStatus = false
        if (localStorage.getItem("time") == null) return false
        let curTime = new Date().getTime()
        let sevenTime = 7 * 24 * 60 * 60 * 1000
        if (curTime - localStorage.getItem("time") > sevenTime) {
          localStorage.removeItem("token")
          localStorage.removeItem("time")
          localStorage.removeItem("username")
          return false
        }
        this.loginStatus = true
        return true
      },
      usernameChange: function(s) {
      // 找到@的位置
      const atIndex = s.indexOf('@');

      // 如果没有@或者@在字符串前三个字符之前，不进行替换
      if (atIndex === -1 || atIndex < 3) {
        return s;
      }

      // 替换从第四个字符到@前两个字符之间的部分
      return s.substring(0, 3) + '*'.repeat(atIndex - 5) + s.substring(atIndex - 2);
      }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style src="@/css/shop.css" scoped>

  </style>
  