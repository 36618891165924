<template>
    <div style="margin-top: 30px; padding: 0 20px;">
        <div class="title">Privacy Policy</div>
        <div class="version">Version 1.0</div>
        <div class="content"><b>DATE LAST MODIFIED: September 1, 2024</b></div>
        <div class="content"><b>AS  STATED IN THE Sichuan Deqin Construction Group Co., Ltd. TERMS OF USE, ALL OF YOUR ACTIVITY THROUGH  THIS SITE INCLUDING THIS PRIVACY POLICY IS SUBJECT TO THE TERMS OF USE -  INCLUDING MANDATORY ARBITRATION PROVISIONS IN THE TERMS OF USE WHICH  REQUIRE YOU TO ARBITRATE ANY CLAIMS AGAINST AS AN INDIVIDUAL AND NOT AS  PART OF A CLASS ACTION. YOU MAY ACCESS THE TERMS OF USE HERE</b> </div>
        <div class="content">
            This application is owned and operated by Sichuan Deqin Construction Group Co., Ltd. We  respect your privacy and understand that you have a right to know why we  collect your personal information and what we do with it. This Privacy  Policy applies to information we collect through our Application (as  defined below) as well as information we may collect offline.
        </div>
        <div class="content">
            This policy describes the type of information we collect from you and/or that you may provide us when you visit and/or use this application and/or any of our websites (individually or collectively, “Application”). “You/your/user(s)” means you as a user of our Application.
        </div>
        <div class="content">
            By accessing  the Application, you acknowledge this Privacy Policy and agree to be  bound by this Privacy Policy. If there is anything you do not  understand, please email any inquiry to <a href="mailto:pisiao34198431@163.com">pisiao34198431@163.com</a>. If at any  time you do not agree to this Privacy Policy, please do not use the  Application or provide us with any personal information.
        </div>
        <div class="content">
            We reserve the right to change or update this Privacy Policy by posting such changes or updates to the Application or emailing you notice of the changes. Amendments to this Privacy Policy will be posted at this URL and will be effective when posted. You can tell if this Privacy Policy has changed by checking the last modified date that appears at the top of this Privacy Policy. Your continued use of the Application following the posting of any amendment, modification or change shall constitute your acceptance thereof.
        </div>
        <div class="title1">
            YOUR SECURITY
        </div>
        <div class="content">
            We strive to keep your Personal Information private and safe. We take commercially reasonable physical, electronic and administrative steps to maintain the security of Personal Information collected, including limiting the number of people who have physical access to database servers, as well as employing electronic security systems and password protections that guard against unauthorized access. 
        </div>
        <div class="content">
            Unfortunately, despite our best efforts, the transmission of data over the Internet cannot be guaranteed to be 100% secure. While we will use reasonable means to ensure the security of information you transmit through the Application, any transmission of Personal Information is at your own risk. We cannot guarantee that such information will not be intercepted by third parties, and we will not be liable for any breach of the security of your Personal Information resulting from causes or events that are beyond our control, including, without limitation, your own act or omission, corruption of storage media, defects in third-party data security products or services, power failures, natural phenomena, riots, acts of vandalism, hacking, sabotage, or terrorism, and we are not responsible for unauthorized circumvention of any privacy settings or security measures contained on the Application.
        </div>
        <div class="title1">
            INFORMATION WE COLLECT
        </div>
        <div class="content">
            We collect various information that identifies you as a person (“Personal Information”) in two ways. First, we collect Personal Information that you provide to us. Second, we collect certain Automated Information that may contain Personal Information when you use our Application.
        </div>
        <div class="title1">
            Personal Information You Provide to Us
        </div>
        <div class="content">
            The Personal Information you provide to us is generally:
        </div>
        <div class="content">
            - contact details including name, email, telephone number, and address;
        </div>
        <div class="content">
            - login and account information, including unique user ID and password;
        </div>
        <div class="content">
            - personal details including date of birth and purchase history;
        </div>
        <div class="content">
            - personal preferences including your wish list as well as marketing preferences.
        </div>
        <div class="content">
            We collect Personal Information directly from you when you provide it to us. This typically occurs when you:
        </div>
        <div class="content">
            - Send us an email or other communication;
        </div>
        <div class="content">
            - Join any of our social networking sites;
        </div>
        <div class="content">
            - Register for an account with us;
        </div>
        <div class="content">
            - Participate in our promotions, surveys, and/or contests;
        </div>
        <div class="content">
            - Play our social games; or
        </div>
        <div class="content">
            - Participate in our sweepstakes.
        </div>
        <div class="title1">
            Automated Information
        </div>
        <div class="content">
            We also collect information, some of which may be Personal Information, through automated means when you visit our Application (“Automated Information”) such as:
        </div>
        <div class="content">
            - the IP address of the device you use to connect to the internet (which may include information about your geographic location);
        </div>
        <div class="content">
            - the unique identifiers of your device;
        </div>
        <div class="content">
            - your browser characteristics;
        </div>
        <div class="content">
            - your device characteristics;
        </div>
        <div class="content">
            - your operating system;
        </div>
        <div class="content">
            - your language preferences;
        </div>
        <div class="content">
            - the URLs through which you were referred to our Site;
        </div>
        <div class="content">
            - information on actions taken by you on our Site;
        </div>
        <div class="content">
            - dates and times of your visits to our Site; or
        </div>
        <div class="content">
            - the pages you accessed on our Site.
        </div>
        <div class="content">
            We collect Automated Information automatically as you navigate through our Site as explained further below.
            We may collect information, which may include certain Personal Information data such as your IP address, from your browser when you use our Application. We use a variety of methods, such as cookies and pixel tags to collect this information, which may include your (a) IP address; (b) unique cookie identifier, cookie information and information on whether your device has software to access certain features; (c) unique device identifier and device type; (d) domain, browser type and language; (e) operating system and system settings; (f) country and time zone; (g) previously visited websites; (h) information about your interaction with our Application such as click behavior, purchases and indicated preferences; and (i) access times and referring URLs.
        </div>
        <div class="content">
            Most web browsers automatically accept cookies. If you do not wish to have cookies on your system, you can set your browser to refuse them. Please review the specific instructions from your browser in order to do this. However, please note that not accepting cookies may make certain features of our Application unavailable or difficult to view or use.
        </div>
        <div class="content">
            In some of our email messages we may use a “click-through URL” linked to content on our Application. When you click one of these URLs, you will pass through our web server before arriving at the destination web page. We track this click-through data to help us determine interest in particular topics and measure the effectiveness of our Application. If you prefer not to be tracked through our emails, simply avoid clicking text or graphic links in the email.
        </div>
        <div class="content">
            Third parties may also collect information via our Application through cookies, third party plug-ins and widgets in order to deliver our targeted advertisements to you across the Internet.
        </div>
        <div class="title1">
            HOW WE USE THE INFORMATION WE COLLECT
        </div>
        <div class="content">
            Most commonly we will use your Personal Information in the following circumstances:
        </div>
        <div class="content">
            - Where you have asked us to do so, or consented to us doing so;
        </div>
        <div class="content">
            - Where we need to do so in order to perform a contract we have entered into with you;
        </div>
        <div class="content">
            - Where it is necessary for our legitimate interests (or those of a third party) and your fundamental;
        </div>
        <div class="content">
            - rights do not override those interests; and
        </div>
        <div class="content">
            - Where we need to comply with a legal or regulatory obligation.
        </div>
        <div class="title1">
            SHARING PERSONAL INFORMATION WITH THIRD PARTIES
        </div>
        <div class="content">
            We are committed to maintaining your trust and we want you to understand when and with whom we may share Personal Information and information collected about you. We do not share your Personal Information with any third parties except as set forth herein.
        </div>
        <div class="content">
            We may disclose your Personal Information to outside individuals and/or companies that help us bring you the products and services we offer and to create, operate, and maintain our Application. For example, we may work with third parties to: (a) manage a database of customer information; (b) assist us in distributing e-mails; (c) assist us with direct marketing and data collection; (d) provide data storage and analysis; (e) provide fraud prevention; (f) provide customer service; (g) provide order fulfillment and/or delivery services; and (h) provide other services designed to assist us in developing and running our Application and maximizing our business potential. We require that these outside companies agree to keep all information shared with them confidential and to use the information only to perform their obligations to us.
        </div>
        <div class="content">
            If we seek investors or go through a business transition, including but not limited to, a merger, acquisition by another company, or a sale of all or a portion of our assets, your Personal Information may be shared as part of the negotiation of the transaction and will likely be among the assets transferred in the event of a sale or partial sale of our assets.
        </div>
        <div class="content">
            We may disclose your Personal Information when legally required to do so, to cooperate with law enforcement investigations or other legal proceedings, to protect against misuse or unauthorized use of the Website, to limit our legal liability and protect our rights or to protect the rights, property or safety of Users of the Application or the public.
        </div>
        <div class="title1">
            TARGETED ADVERTISING AND REMARKETING
        </div>
        <div class="content">
            You may see advertisements for our products/services on other websites and mobile applications because we work with third party advertisers to engage in remarketing and retargeting activities. Through our relationships with these advertisers, we can target messaging to our users by interest-based, demographic, contextual, and other means. These third-party advertisers track your online activities over time and across websites and mobile applications by collecting information through automated means, including through the use of third-party cookies, web server logs, and web beacons. They use this information to show you advertisements that may be tailored to your individual interests. The information our advertising partners may collect includes data about your visits to websites and mobile applications that participate in the relevant advertising networks, such as the pages or advertisements you view and the actions you take on the websites or apps. This data collection takes place both on our Application and on third-party websites and mobile applications that participate in the advertising networks. This process also helps us track the effectiveness of our marketing efforts. Some third-party advertising companies may be advertising networks that are members of the Network Advertising Initiative, which offer a single location to opt out of ad targeting from member companies.
        </div>
        <div class="content">
            See the “Privacy Notice for California Residents”.
        </div>
        <div class="title1">
            OTHER WEBSITES
        </div>
        <div class="content">
            Our Application may contain links or references to websites operated by third parties, or you may have come to our Application using a link found in another website. This does not mean that we endorse these websites or the goods or services they provide. We do not make any representations or warranties about any website that may be linked to the Site. Such other websites are independent from us, and we have no control over, or responsibility for, their information, products or activities. Our privacy practices may differ from those of these other websites. If you provide Personal Information at one of these third-party websites, you are subject to the privacy policy of the operator of that website, not our Privacy Policy. Please make sure you understand the other website's privacy policy before providing such website with any Personal Information.
        </div>
        <div class="content">
            If you use a third-party website or application (e.g. Facebook, Google +, Twitter) to access our Application or your account on our Application, your activities on such third-party sites or apps are governed by the privacy practices of those sites or apps. The privacy policies of other sites and apps may differ significantly from ours, and we have no control over the operation of those sites or apps or the manner in which the collect, store, or process data.
        </div>
        <div class="title1">
            YOUR CHOICES ABOUT HOW WE USE YOUR INFORMATION AND OPTING OUT
        </div>
        <div class="content">
            There are ways by which you can control how your Personal Information is used.
        </div>
        <div class="title1">
            Tracking
        </div>
        <div class="content">
            You can set your browser to refuse some or all cookies or to alert you when cookies are being sent. Please visit your specific browser’s instructions for doing this. The “Help” function on most browsers contains information on how you can set your browser to notify you before accepting cookies, or you can disable them entirely. If you opt out of cookies, you will not be able to take advantage of various features of the Application that are available to other users. For example, we may use cookies to recognize you by name when you return to this site so you don’t have to login again and provide your password each time. If you use more than one browser, you will need to change each browser’s settings in order to refuse cookies.
        </div>
        <div class="title1">
            Promotional Offers
        </div>
        <div class="content">
            If you do not wish to receive our email promotional offers by emailing <a href="mailto:pisiao34198431@163.com">pisiao34198431@163.com</a> or clicking on the “unsubscribe” link found in  emails we send to you. Please allow up to ten (10) business days for  changes to your email preferences to take effect. During that time, you  may continue to receive email communications from us that were already  in process. Opting out of receiving our communications will not affect  your receipt of service-related communications,if we have a data breach,  or other such communications for which we have a legal obligation to  inform you and/or to prevent fraud or harm to our Application, our  business, and/or third parties.
        </div>
        <div class="title1">
            Advertising
        </div>
        <div class="content">
            If you do not want us to use information that we collect for us to deliver advertisements to you, you can opt-out of receiving such advertising.
        </div>
        <div class="content">
            You may opt  out of Internet-based and mobile advertising on your mobile device by  visiting TRUSTe’s Ad Preference Manager, currently available at <a href="https://preferences-mgr.truste.com/" target="_blank">https://preferences-mgr.truste.com/</a>, the Digital Advertising Alliance’s  consumer choice page, currently available at <a href="http://www.aboutads.info/choices/" target="_blank">http://www.aboutads.info/choices/</a>, or the Network Advertising Initiative  (NAI) opt out tool currently available at <a href="http://www.networkadvertising.org/choices/" target="_blank">http://www.networkadvertising.org/choices/</a>.
        </div>
        <div class="content">
            When using the ad industry opt out tools described above, note that: (a) if you opt-out we may still collect some data about your online activity for operational purposes (such as fraud prevention), but it will not be used by us for the purpose of targeting ads to you; (b) if you use multiple browsers or devices you may need to execute this opt out on each browser or device; and (c) other ad companies’ opt-outs may function differently than our opt-out, and we have no control over the practices of any third-parties. We do not make any representations or warranties about such opt-out services. Such services are independent from us, and we have no control over, or responsibility for their performance.
        </div>
        <div class="title1">
            Analytics
        </div>
        <div class="content">
            Our Application use Google Analytics, a web analysis service of Google,  Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043 USA,  <a href="http://www.google.com" target="_blank">www.google.com</a> (“Google Analytics” or “Google”). Google Analytics  employs cookies that are stored to your computer in order to facilitate  an analysis of your use of the site. The information generated by these  cookies, such as time, place and frequency of your visits to our site,  including your IP address, is transmitted to Google’s location in the US  and stored there.
        </div>
        <div class="content">
            Google may also transfer this information to third parties where required to do so by law or where such third parties process this data on Google’s behalf. Google states that it will in never associate your IP address with other data held by Google. You can prevent cookies from being installed by adjusting the settings on your browser software accordingly as noted elsewhere in this Privacy Policy. You should be aware, however, that by doing so you may not be able to make full use of all the functions of our Application.
        </div>
        <div class="content">
            Google Analytics also offers a deactivation add-on for most current browsers that provides you with more control over what data Google can collect on websites you access. The add-on tells the JavaScript (ga.js) used by Google Analytics not to transmit any information about website visits to Google Analytics. However, the browser deactivation add-on offered by Google Analytics does not prevent information from being transmitted to us or to other web analysis services we may engage.
            Google Analytics also uses electronic images known as web beacons (sometimes called single pixel gifs) and are used along with cookies to compile aggregated statistics to analyze how our Application are used.
        </div>
        <div class="content">
            You can find additional information on how to install the browser  add-on referenced above at the following link:  <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank">https://tools.google.com/dlpage/gaoptout?hl=en</a>. Our products and  services use Google Analytics and its associated tracking technologies  to help display our ads you see on other sites, and to help us manage  and optimize our online advertising efforts. To opt out of Google  Analytics Advertising Features, visit  <a href="https://support.google.com/analytics/answer/181881?hl=en" target="_blank">https://support.google.com/analytics/answer/181881?hl=en</a> or you may  access the Google Analytics Opt Out Browser Add-on, currently located at  <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.
        </div>
        <div class="content">
            In addition, we use Clarity (<a href="http://clarity.microsoft.com" target="_blank">clarity.microsoft.com</a>), HotJar  (<a href="http://www.hotjar.com" target="_blank">www.hotjar.com</a>), AppsFlyer (<a href="http://www.appsflyer.com/hp2/" target="_blank">www.appsflyer.com/hp2/</a>) , Bloomreach  (<a href="http://www.bloomreach.com" target="_blank">www.bloomreach.com</a>), Seon (www.seon.io) and Graphyte (graphyte.ai) for  analytics.
        </div>
        <div class="title1">
            DISCLOSURE FOR LEGAL PURPOSES
        </div>
        <div class="content">
            You hereby authorize us to disclose any of your Personal Information pursuant to judicial and administrative proceedings and to law enforcement or government agencies if we are legally required to do so. You also authorize us to disclose Personal Information if we believe the disclosure is necessary or appropriate in the event of an investigation of improper or illegal conduct in connection with the Application, such as fraud, misrepresentation, intellectual property infringement, or other activity that may put us at risk for liability.
        </div>
        <div class="title1">
            RIGHT OF ERASURE
        </div>
        <div class="content">
            You can request us to erase your personal data where there is no  compelling reason to continue processing. This right only applies in  certain circumstances, it is not a guaranteed or absolute right. Please  email <a href="mailto:pisiao34198431@163.com">pisiao34198431@163.com</a> if you wish to request erasure of your  personal data.
        </div>
        <div class="title1">
            DISPUTES
        </div>
        <div class="content">
            This Privacy Policy will be interpreted in accordance with the laws of the State of Delaware and the United States of America, without regard to its conflict-of-law provisions. By visiting the Application, you agree that, except as otherwise specified herein, the laws of the State of Delaware without regard to principles of conflict of laws, will govern any dispute of any sort that might arise between us or any of our affiliates regarding your visit and use of the Application.
        </div>
        <div class="content">
            We and you agree that in the event of any dispute, the party wishing to  address the dispute must contact the other party in writing, including  by e-mail, and advise the other party of the dispute in reasonable  detail as well as informing the other party of the remedy being sought.  We may send you notices via the email address you provide to us, and all  notices to us shall be sent to the following email address: pisiao34198431@163.com and include the words “DISPUTE NOTICE” in the  subject. The parties shall then make a good faith effort to resolve the  dispute before resorting to more formal means of resolution. In the  event that the dispute is not resolved through this procedure, the party  raising the dispute may proceed to mandatory arbitration as set forth  in the Terms of Use.
        </div>
        <div class="title1">
            CHILDREN
        </div>
        <div class="content">
            We do not knowingly permit persons under 18 years of age to use the Application, and we do not knowingly collect, use or disclose Personal Information from anyone under 18 years of age. If we become aware that we have unknowingly collected Personal Information from a child under the age of 18, we will make reasonable efforts to delete such information from our records.
        </div>
        <div class="title1">
            CONFIDENTIAL INFORMATION
        </div>
        <div class="content">
            We do not want you to send to us any confidential or proprietary information through email or otherwise. Any information, materials, suggestions, ideas or comments sent to us will be considered non-confidential, and by submitting the same to us, you are giving us the absolute right to use, modify, reproduce, transmit, display and distribute the information for any purpose whatsoever, with no payment or other compensation to you. However, we will not use your name unless we are required by law to identify the source of the materials, information, suggestions, ideas or comments, or unless we first obtain your permission.
        </div>
        <div class="title1">
            ASSIGNMENT
        </div>
        <div class="content">
            We may freely assign this Privacy Policy or any of our rights and/or obligations hereunder to any successor.
        </div>
        <div class="title1">
            CONTACT
        </div>
        <div class="content">
            If you have any questions about your privacy or security at the  Application, or wish to update your Personal Information, please send an  email to pisiao34198431@163.com.
        </div>
    </div>
</template>
<script>
export default {
    name:"PrivacyView",
    title: "Privacy Policy",
    methods: {
        onClickHF: function(){
            this.$router.push("/")
        }
    }
}
</script>
<style lang="scss" scoped>
*{
    font-size: 18px;
}
.title {
    display: block;
    text-align: center;
    font-weight: bold;
}

.title1 {
    display: block;
    text-align: left;
    font-weight: bold;
}

.version {
    display: block;
    text-align: left;
}

.content {
    display: block;
    text-align: left;
}

div {
    margin: 12px;
}

a {
    text-decoration: none;
}
</style>