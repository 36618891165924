// http.js
import axios from 'axios';
 
const instance = axios.create({
  baseURL: 'https://api.gamewonder.co',
});
 
export function request(config) {
  return new Promise((resolve, reject) => {
    instance(config)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}
